"use client"
import { Lightbulb, Volume2 } from "lucide-react"

function QuestionsSection({ mockInterviewQuestions, activeQuestionIndex }) {
  const textToSpeech = (text) => {
    if ("speechSynthesis" in window) {
      const speech = new SpeechSynthesisUtterance(text)
      window.speechSynthesis.speak(speech)
    } else {
      alert("Sorry, your browser does not support text to speech")
    }
  }

  return (
    mockInterviewQuestions && (
      <div className="p-5 border rounded-lg my-10">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {mockInterviewQuestions.map((question, index) => (
            <h2
              key={index}
              className={`p-2 border rounded-full
            text-xs md:text-sm text-center cursor-pointer
            ${activeQuestionIndex === index ? "bg-green-700 text-white" : "bg-gray-200 text-black"}`}
            >
              Question #{index + 1}
            </h2>
          ))}
        </div>
        <h2 className="my-5 text-md md:text-lg">{mockInterviewQuestions[activeQuestionIndex]?.question}</h2>
        <Volume2
          className="cursor-pointer"
          onClick={() => textToSpeech(mockInterviewQuestions[activeQuestionIndex]?.question)}
        />

        <div className="border rounded-lg p-5 bg-blue-100 mt-20">
          <h2 className="flex gap-2 items-center text-primary">
            <Lightbulb />
            <strong>Note:</strong>
          </h2>
          <h2 className="text-sm text-primary my-2">
            Take your time to think about your answer. Speak clearly and confidently. Try to structure your answer with
            an introduction, main points, and a conclusion.
          </h2>
        </div>
      </div>
    )
  )
}

export default QuestionsSection

