// data/friendData.js
export const initialFriendData = [
    {
      name: "Satyam Yadav",
      avatar: "https://assets.leetcode.com/users/PURU_07/avatar_1720937494.png",
      stats: {
        easy: 82,
        medium: 53,
        hard: 17,
        lectures: {
          dsa: 40,
          flutter: 15,
          webDev: 24,
        },
        tasks: 86,
        streak: 12,
      },
      dailyTasks: [
        "Solve Leetcode",
        "5 DSA Lectures",
        "Revision DSA",
        "Web Dev 2 Lec",
        "Project Work",
      ],
    },
    {
      name: "Priya Singh",
      avatar: "https://assets.leetcode.com/users/pkc5uOVzbM/avatar_1718561176.png",
      stats: {
        easy: 65,
        medium: 42,
        hard: 12,
        lectures: {
          dsa: 35,
          flutter: 10,
          webDev: 18,
        },
        tasks: 72,
        streak: 8,
      },
      dailyTasks: [
        "Solve HackerRank",
        "3 DSA Lectures",
        "System Design",
        "React Workshop",
        "Open Source Contrib",
      ],
    },
    {
      name: "Rahul Sharma",
      avatar: "https://avatars.githubusercontent.com/u/167583328?v=4",
      stats: {
        easy: 95,
        medium: 61,
        hard: 25,
        lectures: {
          dsa: 45,
          flutter: 20,
          webDev: 30,
        },
        tasks: 92,
        streak: 15,
      },
      dailyTasks: [
        "Solve CodeChef",
        "6 DSA Lectures",
        "Algo Practice",
        "Cloud Computing",
        "Hackathon Prep",
      ],
    }
  ];