// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "sonner"; 


// Components
import ModernHomePage from './components/home/ModernHomePage'; 
import AuthPages from './components/auth/AuthPages'; 
import Profile from "./components/profile/profile";
import "./components/home/ModernHomePage.css"
import Dashboard from "./components/dashboard/dashboard";
import JobReady from "./components/InterviewPratice/InterviewRoutes";
import Learn from "./components/Learn/learn";
import Friend from "./components/Friends/friend";
import Internships  from "./components/Internships/internships";
import InternshipAdmin from "./components/Internships/InternshipAdmin";
import InternshipDetail from "./components/Internships/InternshipDetail";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ModernHomePage />} />

        <Route path="/auth" element={<AuthPages />} />

        <Route path="/profile" element={<Profile />} />

        <Route path="/dashboard" element={ <Dashboard />} />

        <Route path="/interview" element={ <JobReady />} />

        <Route path="/learn" element={<Learn />} />

        <Route path="/friends" element={<Friend />} /> 

        <Route path="/internships" element={<Internships />} />  

        <Route path="/admin" element={<InternshipAdmin />} />

        <Route path="/internship/:id" element={<InternshipDetail />} />

        
        


      </Routes>
      
      <Toaster richColors position="top-right" />

    </Router>
  );
}

export default App;
